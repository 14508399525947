import { createSlice } from "@reduxjs/toolkit"

export const ContactSlice = createSlice({
    name: "contact",
    initialState: {
        contactList: [],
        singleDetail: {},
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setContactList: (state, action) => {
            state.contactList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.singleDetail = action.payload
        }
    }
}) 

export const {
    setContactList,
    setFreshList,
    setSingleData,
    setTotalEntry,
} = ContactSlice.actions

export const selectContactList = (state) => state.contact.contactList
export const selectFreshList = (state) => state.contact.isFreshData
export const selectTotalEntry = (state) => state.contact.totalEntry
export const selectSingleDetail = (state) => state.contact.singleDetail

export default ContactSlice.reducer
