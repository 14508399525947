import { createSlice } from "@reduxjs/toolkit"

export const FaqSlice = createSlice({
    name: "FAQ",
    initialState: {
        faqList: [],
        singleDetail: {},
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setFaqList: (state, action) => {
            state.faqList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.singleDetail = action.payload
        }
    }
})

export const {
    setFaqList,
    setFreshList,
    setSingleData,
    setTotalEntry,
} = FaqSlice.actions

export const selectFaqList = (state) => state.faq.faqList
export const selectFreshList = (state) => state.faq.isFreshData
export const selectTotalEntry = (state) => state.faq.totalEntry
export const selectSingleDetail = (state) => state.faq.singleDetail

export default FaqSlice.reducer
