import { createSlice } from "@reduxjs/toolkit"

export const SportSlice = createSlice({
    name: "Sport",
    initialState: {
        sportList: [], 
        singleDetail: {},
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setSportList: (state, action) => {
            state.sportList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.singleDetail = action.payload
        }
    }
})

export const {
    setSportList,
    setFreshList,
    setSingleData,
    setTotalEntry,
} = SportSlice.actions

export const selectSportList = (state) => state.sport.sportList
export const selectFreshList = (state) => state.sport.isFreshData
export const selectTotalEntry = (state) => state.sport.totalEntry
export const selectSingleDetail = (state) => state.sport.singleDetail

export default SportSlice.reducer
