import { createSlice } from "@reduxjs/toolkit"

export const WalletSlice = createSlice({
    name: "wallet",
    initialState: {
        walletList: [],
        walletAcceptList: [],
        singleDetail: {},
        isFreshData: false,
        isAcceptedFreshData: false,
        totalEntryAccepted: 0,
    },
    reducers: {
        setWalletList: (state, action) => {
            state.walletList = action.payload
        },
        setWalletAcceptList: (state, action) => {
            state.walletAcceptList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setAcceptedFreshList: (state, action) => {
            state.isAcceptedFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setTotalEntryAccepted: (state, action) => {
            state.totalEntryAccepted = action.payload
        },
        setSingleData: (state, action) => {
            state.singleDetail = action.payload
        }
    }
})

export const {
    setWalletList,
    setWalletAcceptList,
    setFreshList,
    setAcceptedFreshList,
    setSingleData,
    setTotalEntry,
    setTotalEntryAccepted
} = WalletSlice.actions

export const selectWalletList = (state) => state.wallet.walletList
export const selectWalletAcceptList = (state) => state.wallet.walletAcceptList
export const selectFreshList = (state) => state.wallet.isFreshData
export const selectAcceptedFreshList = (state) => state.wallet.isAcceptedFreshData
export const selectTotalEntry = (state) => state.wallet.totalEntry
export const selectTotalEntryAccepted = (state) => state.wallet.totalEntryAccepted
export const selectSingleDetail = (state) => state.wallet.singleDetail

export default WalletSlice.reducer
