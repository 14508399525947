import { createSlice } from "@reduxjs/toolkit"

export const bannerSlice = createSlice({
    name: "banner",
    initialState: {
        bannerList: [],
        categoryList: [],
        exportList: [],
        bannerDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setBannerList: (state, action) => {
            state.bannerList = action.payload
        },
        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.bannerDetail = action.payload
        }
    }
})

export const {
    setBannerList,
    setCategoryList,
    setExportList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = bannerSlice.actions

export const selectBannerList = (state) => state.banner.bannerList
export const selectCategoryList = (state) => state.banner.categoryList
export const selectExportList = (state) => state.banner.exportList
export const selectFilterTerm = (state) => state.banner.filterTerm
export const selectTotalEntry = (state) => state.banner.totalEntry
export const selectBannerDetail = (state) => state.banner.bannerDetail

export default bannerSlice.reducer
