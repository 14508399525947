import { createSlice } from "@reduxjs/toolkit"

export const programmeSlice = createSlice({
  name: "programmeSlice",
  initialState: {
    programmeList: [],
    exportList: [],
    detail: {},
    totalEntry: 0, 
    isFreshData: false,
  },  
  reducers: {
    setProgrammeList: (state, action) => {
      state.programmeList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
  },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.detail = action.payload
    }

  }
})

export const {
  setProgrammeList,
  setFreshList,
  setExportList,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = programmeSlice.actions

export const selectProgrammeList = (state) => state.programme.programmeList
export const selectFreshList = (state) => state.sport.isFreshData
export const selectExportList = (state) => state.programme.exportList
export const selectTotalEntry = (state) => state.programme.totalEntry
export const selectSingleDetail = (state) => state.programme.detail

export default programmeSlice.reducer
