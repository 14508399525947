import { createSlice } from "@reduxjs/toolkit"

export const NotificationSlice = createSlice({
    name: "Notification",
    initialState: {
        notificationList: [],
        singleDetail: {},
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setNotificationList: (state, action) => {
            state.notificationList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.singleDetail = action.payload
        }
    }
})

export const {
    setNotificationList,
    setFreshList,
    setSingleData,
    setTotalEntry,
} = NotificationSlice.actions

export const selectNotificationList = (state) => state.notification.notificationList
export const selectFreshList = (state) => state.notification.isFreshData
export const selectTotalEntry = (state) => state.notification.totalEntry
export const selectSingleDetail = (state) => state.notification.singleDetail

export default NotificationSlice.reducer
