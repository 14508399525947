import { createSlice } from "@reduxjs/toolkit"

export const BankSlice = createSlice({
    name: "Bank",
    initialState: {
        bankList: [],
        singleDetail: {},
        isFreshData: false,
        totalEntry: 0,
    },
    reducers: {
        setBankList: (state, action) => {
            state.bankList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.singleDetail = action.payload
        }
    }
})

export const {
    setBankList,
    setFreshList,
    setSingleData,
    setTotalEntry,
} = BankSlice.actions

export const selectBankList = (state) => state.bank.bankList
export const selectFreshList = (state) => state.bank.isFreshData
export const selectTotalEntry = (state) => state.bank.totalEntry
export const selectSingleDetail = (state) => state.bank.singleDetail

export default BankSlice.reducer
