import { createSlice } from "@reduxjs/toolkit"

export const couponSlice = createSlice({
    name: "coupon",
    initialState: {
        couponList: [],
        exportList: [],
        couponDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setCouponList: (state, action) => {
            state.couponList = action.payload
        },
        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.couponDetail = action.payload
        }
    }
})

export const {
    setCouponList,
    setExportList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = couponSlice.actions

export const selectCouponList = (state) => state.coupon.couponList
export const selectExportList = (state) => state.coupon.exportList
export const selectFilterTerm = (state) => state.coupon.filterTerm
export const selectTotalEntry = (state) => state.coupon.totalEntry
export const selectCouponDetail = (state) => state.coupon.couponDetail

export default couponSlice.reducer
