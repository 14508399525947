import { createSlice } from "@reduxjs/toolkit"

export const academySlice = createSlice({
    name: "academy",
    initialState: {
        academyList: [],
        countryList: [],
        exportList: [],
        academyDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    },   
    reducers: {
        setAcademyList: (state, action) => {
            state.academyList = action.payload
        },
        setCountryList: (state, action) => {
            state.countryList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.academyDetail = action.payload
        }
    }
})

export const {
    setAcademyList,
    setFreshList,
    setCountryList,
    setExportList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = academySlice.actions

export const selectAcademyList = (state) => state.academy.academyList
export const selectFreshData = (state) => state.academy.isFreshData
export const selectCountryList = (state) => state.academy.countryList
export const selectExportList = (state) => state.academy.exportList
export const selectFilterTerm = (state) => state.academy.filterTerm
export const selectTotalEntry = (state) => state.academy.totalEntry
export const selectAcademyDetail = (state) => state.academy.academyDetail

export default academySlice.reducer
