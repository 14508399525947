import { createSlice } from "@reduxjs/toolkit"

export const stringSlice = createSlice({
    name: "string",
    initialState: {
        stringList: [],
        stringDetail: {},
        isFreshData: false,
        totalEntry: 0,
    },
    reducers: {
        setStringList: (state, action) => {
            state.stringList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.stringDetail = action.payload
        }
    }
})

export const {
    setStringList,
    setSingleData,
    setTotalEntry,
} = stringSlice.actions

export const selectStringList = (state) => state.string.stringList
export const selectTotalEntry = (state) => state.string.totalEntry
export const selectStringDetail = (state) => state.string.stringDetail

export default stringSlice.reducer
