export default {
    labels: {
        //  appName
        appName: "AFA Coaching Panel",
        // loginPage
        welcome: 'Welcome to AFA Coaching Panel',
        menuName: 'AFA Panel',
        footerName: 'AFA Coaching'
    },

    strings: {
        image_description: " Note : Please upload Image less then 2 MB and above 800px resolution.",
        sure_active: "Are you sure you want to activate this user?",
    },

    validations: {
        ice_breaker_message: "Please enter message",
    }
}