import { createSlice } from "@reduxjs/toolkit"

export const deliverySlice = createSlice({
    name: "delivery",
    initialState: {
        deliveryList: [],
        exportList: [],
        deliveryDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    },   
    reducers: {
        setDeliveryList: (state, action) => {
            state.deliveryList = action.payload
        },
        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.deliveryDetail = action.payload
        }
    }
})

export const {
    setDeliveryList,
    setExportList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = deliverySlice.actions

export const selectDeliveryList = (state) => state.delivery.deliveryList
export const selectExportList = (state) => state.delivery.exportList
export const selectFilterTerm = (state) => state.delivery.filterTerm
export const selectTotalEntry = (state) => state.delivery.totalEntry
export const selectDeliveryDetail = (state) => state.delivery.deliveryDetail

export default deliverySlice.reducer
