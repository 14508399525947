import { createSlice } from "@reduxjs/toolkit"

export const countrySlice = createSlice({
  name: "country",
  initialState: {
    countryList: [],  
    isFreshData: false,
    totalEntry: 0,
    filterTerm: ''
  }, 
  reducers: {
    setCountryList: (state, action) => {
      state.countryList = action.payload
    }, 
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.countryDetail = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
  },
  }
})

export const {
  setCountryList,
  setExportList,
  setFreshList,
  setFilterTerm,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = countrySlice.actions

export const selectCountryList = (state) => state.country.countryList
export const selectFilterTerm = (state) => state.country.filterTerm
export const selectFreshList = (state) => state.country.isFreshData
export const selectTotalEntry = (state) => state.country.totalEntry

export default countrySlice.reducer
