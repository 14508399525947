// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import auth from "./slices/auth.Slice";
import country from "./slices/countrySlice";
import academy from "./slices/academySlice";
import users from "./slices/usersSlice";
import sport from "./slices/sportSlice";
import coach from "./slices/coachSlice";
import programme from "./slices/programmeSlice";
import banner from "./slices/bannerSlice";
import bank from "./slices/bankSlice";
import faq from "./slices/faqSlice";
import wallet from "./slices/walletSlice";
import contact from "./slices/contactSlice";
import category from "./slices/categorySlice";
import product from "./slices/productSlice";
import delivery from "./slices/deliverySlice";
import string from "./slices/stringSlice";
import coupon from "./slices/couponSlice";
import blog from "./slices/blogSlice";
import notification from "./slices/notificationSlice";
import feedback from "./slices/feedbackSlice";

const rootReducer = {
  navbar,
  layout,
  auth,
  country,
  academy,
  users,
  sport,
  coach,
  programme,
  banner,
  bank,
  faq,
  wallet,
  contact,
  category,
  product,
  coupon,
  blog,
  notification,
  string,
  delivery,
  feedback,
};

export default rootReducer;
