import { createSlice } from "@reduxjs/toolkit"

export const feedbackSlice = createSlice({
    name: "feedback",
    initialState: {
        feedbackList: [],
        feedbackDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    },
    reducers: {
        setFeedbackList: (state, action) => {
            state.feedbackList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.feedbackDetail = action.payload
        }
    }
})

export const {
    setFeedbackList,
    setFreshList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = feedbackSlice.actions

export const selectFeedbackList = (state) => state.feedback.feedbackList
export const selectFreshData = (state) => state.feedback.isFreshData
export const selectFilterTerm = (state) => state.feedback.filterTerm
export const selectTotalEntry = (state) => state.feedback.totalEntry
export const selectFeedbackDetail = (state) => state.feedback.feedbackDetail

export default feedbackSlice.reducer
