import { createSlice } from "@reduxjs/toolkit"

export const blogSlice = createSlice({
    name: "blog",
    initialState: {
        blogList: [],
        isFreshData: false,
        totalEntry: 0,
    },
    reducers: {
        setBlogList: (state, action) => {
            state.blogList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.blogDetail = action.payload
        }
    }
})

export const {
    setBlogList,
    setFreshList,
    setSingleData,
    setTotalEntry,
} = blogSlice.actions

export const selectBlogList = (state) => state.blog.blogList
export const selectFreshList = (state) => state.blog.isFreshData
export const selectTotalEntry = (state) => state.blog.totalEntry

export default blogSlice.reducer
