import { createSlice } from "@reduxjs/toolkit"

export const coachSlice = createSlice({
  name: "coachSlice",
  initialState: {
    coachList: [],
    exportList: [],
    detail: {},
    totalEntry: 0, 
    isFreshData: false,
  },  
  reducers: {
    setCoachList: (state, action) => {
      state.coachList = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
  },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.detail = action.payload
    }

  }
})

export const {
  setCoachList,
  setFreshList,
  setExportList,
  setSingleData,
  setTotalEntry,
  setFilteredList
} = coachSlice.actions

export const selectCoachList = (state) => state.coach.coachList
export const selectFreshList = (state) => state.coach.isFreshData
export const selectExportList = (state) => state.coach.exportList
export const selectTotalEntry = (state) => state.coach.totalEntry
export const selectSingleDetail = (state) => state.coach.detail

export default coachSlice.reducer
